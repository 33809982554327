import React, { useContext } from 'react';
import { formatAsCurrency } from '../../util';
import { EventSectionsCartModel } from '../event-sections/EventSectionsForm';
import { AuthContext } from '../../context/AuthContext';
import { CartCouponInfo } from '../event-sections/CartCouponInfo';

const ConferenceDaysSummary = () => {
  const auth = useContext(AuthContext);
  const cart = auth?.authState?.cart?.payload as EventSectionsCartModel;
  const couponUse = (auth?.authState?.cart as any)?.couponUse;
  if (cart?.items?.length === 0) return <></>;
  return (
    <>
      <h3 className="text-lg mb-2">Event Cart</h3>
      {cart.items.map(item => (
        <div key={item.id} className="mb-2 pb-2 border-b">
          <div className="text-primary mb-2">{item.title}</div>
          <div className="my-1 text-xs italic text-gray-500">
            Number of Tickets: {item.numberOfTickets}
          </div>
          <div className="my-1 text-xs italic text-gray-500">
            Subtotal:{' '}
            {formatAsCurrency(
              (item?.ticketType?.price * item.numberOfTickets) / 100
            )}
          </div>
        </div>
      ))}
      <div className="mb-2">
        <div className="mb-1 italic text-gray-500">
          Total:{' '}
          {formatAsCurrency(
            cart.items.reduce(
              (accum, item) =>
                (accum += item?.ticketType?.price * item.numberOfTickets),
              0
            ) / 100
          )}
        </div>
      </div>
      {!!couponUse?.coupon && (
        <CartCouponInfo className="mb-6" cart={cart} couponUse={couponUse} />
      )}
    </>
  );
};

export default ConferenceDaysSummary;
