import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { makeAddressForDisplay } from '../../../util';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import {
  createOwnOrganization,
  createOwnOrganizationAffiliation,
  deleteOwnOrganizationAffiliation,
  editOwnOrganizationAffiliation,
  getAffiliationsForUser
} from '../api';
import Button from '../../common/Button';

import { QueryReturnState } from '../Enum';
import AddAffiliationForm, {
  AddOwnAffiliationForm
} from './AddAffiliationForm';
import { FETCH_STATES, useFetch } from '../../../hooks/useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
  CreateOrganizationFromReferenceType,
  CreateOrganizationType
} from '../CreateOrganizationModal';
import { toast } from 'react-toastify';
import { Organization, OrganizationMembership } from '../NewApiTypes.generated';
import { getUserInfo } from '../../../util/auth';
import { AuthContext } from '../../../context/AuthContext';
import { EventManagementContext } from '../EventManagement';

interface AffiliatonsProps {
  authState: any;
  hideShadow?: boolean;
  isReview?: boolean;
}

const Affiliations = ({
  hideShadow = false,
  isReview = false
}: AffiliatonsProps) => {
  const { setIsSomeOrgAffiliationAdded } = useContext(EventManagementContext);
  const [isAffilitionAdding, setIsAffiliationAdding] = useState(false);
  const [
    affiliationForEdit,
    setAffiliationForEdit
  ] = useState<null | Organization>(null);

  const auth = useContext(AuthContext);
  const { data, isLoading, refetch } = useQuery<{
    state: QueryReturnState;
    organizations?: Organization[];
    organizationMemberships?: OrganizationMembership[];
  }>('affiliations', () => getAffiliationsForUser());

  const { state: createAffiliationState, apply: createAffiliation } = useFetch(
    createOwnOrganizationAffiliation
  );

  const { state: editAffiliationState, apply: editAffiliation } = useFetch(
    editOwnOrganizationAffiliation
  );

  const { state: deleteAffiliationState, apply: deleteAffiliation } = useFetch(
    deleteOwnOrganizationAffiliation
  );

  const refetchUserAndUpdateAuth = async () => {
    const user = await getUserInfo();
    auth.setAuthState(user || null);
  };

  useEffect(() => {
    setIsSomeOrgAffiliationAdded(!!data?.organizationMemberships?.length);
  }, [data?.organizationMemberships]);

  const handleSubmitNewAffiliation = async (values: AddOwnAffiliationForm) => {
    await createAffiliation({
      ...values,
      organization: values.organization.value
    });

    refetch();
    refetchUserAndUpdateAuth();
    setIsAffiliationAdding(false);
  };

  const handleSubmitEditAffiliation = async (values: AddOwnAffiliationForm) => {
    await editAffiliation({
      ...values,
      organization: values.organization.value
    });

    refetch();
    refetchUserAndUpdateAuth();
    setIsAffiliationAdding(false);
    setAffiliationForEdit(null);
  };

  const handleSubmitDeleteAffiliation = async (id: string) => {
    await deleteAffiliation({ organization: id });

    refetch();
    refetchUserAndUpdateAuth();
    setIsAffiliationAdding(false);
    setAffiliationForEdit(null);
  };

  const handleEditAffiliationClick = (organization?: Organization) => {
    setAffiliationForEdit(organization || null);
    setIsAffiliationAdding(false);
  };

  const {
    state: createOwnOrganizationState,
    apply: createOrganization
  } = useFetch(createOwnOrganization);

  const handleSubmitCreateOwnOrganization = async (
    values: CreateOrganizationType | CreateOrganizationFromReferenceType
  ) => {
    try {
      const { data, state, error } = await createOrganization(values);

      if (state === 'SUCCESS') {
        toast.success(`Success! New Organization Created!`);
        setIsAffiliationAdding(false);
        setAffiliationForEdit(null);

        await refetch();
      } else {
        toast.error(error || data?.message || 'Something went wrong');
      }
    } catch (err) {
      toast.error((err as any)?.message || 'Something went wrong');
    }
  };

  return (
    <Card className={hideShadow ? 'shadow-none' : ''}>
      <h3
        className={`text-primary font-bold mb-4 ${
          isReview ? 'text-lg' : 'text-2xl'
        }`}
      >
        My Affiliated Schools
      </h3>
      {isLoading ? <LoadingDialog /> : null}
      {data &&
      data.organizationMemberships &&
      data.organizationMemberships.length ? (
        <>
          {data.organizationMemberships.map((orgM, idx) => (
            <Fragment key={orgM.id}>
              {!!idx && <hr className="mb-3 " />}

              {affiliationForEdit &&
              affiliationForEdit.id === orgM.organization?.id ? (
                <div className="my-2">
                  <AddAffiliationForm
                    onCancel={() => setAffiliationForEdit(null)}
                    onSubmit={handleSubmitEditAffiliation}
                    onDelete={() =>
                      handleSubmitDeleteAffiliation(orgM.organization?.id || '')
                    }
                    deleteLoading={
                      deleteAffiliationState === FETCH_STATES.PROCESSING
                    }
                    organizationMembership={orgM}
                    loading={editAffiliationState === FETCH_STATES.PROCESSING}
                    onCreateOrganizationSubmit={
                      handleSubmitCreateOwnOrganization
                    }
                    loadingCreateOrganization={
                      createOwnOrganizationState === FETCH_STATES.PROCESSING
                    }
                  />
                </div>
              ) : (
                <div className="relative mb-4 flex">
                  <div className="w-1/2">
                    <p className="sans-serif text-gray-400">ORGANIZATION</p>

                    <p
                      className={`sans-serif font-bold text-gray-600 ${!isReview &&
                        'text-xl'}`}
                    >
                      {orgM.organization?.name}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {orgM.organization?.district?.name}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {makeAddressForDisplay(
                        orgM.organization.mailingAddress || {}
                      )}
                    </p>
                    {!isReview && (
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="fa-xs text-gray-400 cursor-pointer absolute right-2 top-2"
                        onClick={() =>
                          handleEditAffiliationClick(orgM.organization)
                        }
                      />
                    )}
                  </div>

                  <div className="w-1/4 mx-3">
                    <p className="sans-serif text-gray-400">POSITION</p>
                    <p className="sans-serif">{orgM.position?.label}</p>
                  </div>

                  <div className="w-1/4">
                    <p className="sans-serif text-gray-400">GRADES</p>
                    <p className="sans-serif">
                      {orgM.grades?.map(g => g?.name || '').join(', ')}
                    </p>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </>
      ) : null}
      {!isReview ? (
        !isAffilitionAdding ? (
          <Button
            onClick={() => {
              setIsAffiliationAdding(true);
              setAffiliationForEdit(null);
            }}
            text="+ Add Affiliation"
            size="sm"
            className="mt-3"
          />
        ) : (
          <>
            <hr className="my-5" />
            <AddAffiliationForm
              onCancel={() => setIsAffiliationAdding(false)}
              onSubmit={handleSubmitNewAffiliation}
              loading={createAffiliationState === FETCH_STATES.PROCESSING}
              onCreateOrganizationSubmit={handleSubmitCreateOwnOrganization}
              loadingCreateOrganization={
                createOwnOrganizationState === FETCH_STATES.PROCESSING
              }
            />
          </>
        )
      ) : null}
    </Card>
  );
};

export default Affiliations;
