import {
  faCheck,
  faPlus,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { states } from '../../data/states';
import { FormField, FormWrapper } from '../common/Form';
import { QueryReturnState } from './Enum';
import { useQuery } from 'react-query';
import {
  getDistricts,
  getOrganizationTypes,
  getReferenceOrganizations
} from './api';
import { IDistrict, IOrganizationType } from './NewApiTypes';
import { SEARCH_DEBOUNCE_TIME } from '../../constants/input';
import { debounce } from 'lodash';
import { District } from './NewApiTypes.generated';
import classNames from 'classnames';
import Button from '../common/Button';
import { formatPhoneNumber } from '../../util';

export type CreateOrganizationType = {
  organizationType: string;
  organizationName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  country: string;
  zipPostal: string;
  phone: string;
  position: string;
  grades: string[];
  district?:
    | { value: string | undefined; label: string | undefined }
    | undefined;
  isUnaffiliatedToDistrict?: boolean;
  districtName?: string;
};

export type CreateOrganizationFromReferenceType = {
  organizationId?: string;
  referenceOrganizationId?: string;
  position: string;
  grades: string[];
};

type IForm = CreateOrganizationType;

const CreateOrganizationSchema: Yup.SchemaOf<CreateOrganizationType> = Yup.object().shape(
  {
    organizationType: Yup.string().required('Selection required'),
    organizationName: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Address 1 is required'),
    addressLine2: Yup.string(),
    city: Yup.string().required('City is required'),
    stateProvince: Yup.string().required('State / Province is required'),
    country: Yup.string().required('Country is required'),
    zipPostal: Yup.string().required('Zip / Postal code is required'),
    phone: Yup.string().required('Phone is required'),
    position: Yup.string().required('Selection required'),
    grades: Yup.array()
      .min(1, 'Selection required')
      .required('Required'),
    district: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string()
    }),
    isUnaffiliatedToDistrict: Yup.boolean(),
    districtName: Yup.string()
  }
);

const CreateOrganizationFromReferenceSchema: Yup.SchemaOf<CreateOrganizationFromReferenceType> = Yup.object().shape(
  {
    position: Yup.string().required('Selection required'),
    grades: Yup.array()
      .min(1, 'Selection required')
      .required('Required'),
    organizationId: Yup.string().optional(),
    referenceOrganizationId: Yup.string().optional()
  }
);

interface CreateOrganizationModalProps {
  onSubmit: (
    values: CreateOrganizationType | CreateOrganizationFromReferenceType
  ) => void;
  isLoading: boolean;
  onCancel: () => void;
  positionOptions: { value: string; label: string }[];
  gradeOptions: { value: string; label: string }[];
  isLoadingPositions?: boolean;
  isLoadingGrades?: boolean;
}

type RefOrg = {
  id?: string;
  refId?: string;
  name: string;
  district: string;
  addressL1: string;
  addressL2: string;
  phone: string;
};

const SEARCH_ITEMS_LENGTH = 10;

const CreateOrganizationModal = (props: CreateOrganizationModalProps) => {
  const [isDistrictSelectMode, setIsDistrictSelectMode] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [pagination, setPagination] = useState({
    skip: 0,
    take: SEARCH_ITEMS_LENGTH
  });
  const [referenceOrganizations, setReferenceOrganizations] = useState<
    RefOrg[]
  >([]);
  const [
    selectedReferenceOrganization,
    setSelectedReferenceOrganization
  ] = useState<RefOrg | null>(null);
  const [useReferenceOrganization, setUseReferenceOrganization] = useState(
    false
  );
  const [
    isReferenceOrganizationLoading,
    setIsReferenceOrganizationLoading
  ] = useState(false);

  const {
    data: organizationTypesData,
    isLoading: isLoadingOrganizationTypes
  } = useQuery<{
    state: QueryReturnState;
    organizationTypes?: IOrganizationType[];
  }>('organizationTypes', getOrganizationTypes);

  const [isLoadingDistricts, setDistrictLoading] = useState(false);

  const formik = useFormik<CreateOrganizationType>({
    validationSchema: CreateOrganizationSchema,
    initialValues: {
      organizationType: '',
      organizationName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateProvince: '',
      country: 'United States',
      zipPostal: '',
      phone: '',
      position: '',
      grades: [],
      district: undefined,
      isUnaffiliatedToDistrict: false,
      districtName: ''
    },
    onSubmit: values => {
      const { district, districtName, ...data } = values;

      const dt = isDistrictSelectMode ? { district } : { districtName };
      props.onSubmit({
        ...data,
        ...dt,
        phone: data.phone.replace(/[^\d]/g, '')
      });
    }
  });

  const referenceOrganizationFormik = useFormik<
    CreateOrganizationFromReferenceType
  >({
    validationSchema: CreateOrganizationFromReferenceSchema,
    initialValues: {
      position: '',
      grades: []
    },
    onSubmit: values => {
      props.onSubmit({
        ...values,
        organizationId: selectedReferenceOrganization?.id || '',
        referenceOrganizationId: selectedReferenceOrganization?.refId || ''
      });
    }
  });

  const handleChangeMode = () => {
    setIsDistrictSelectMode(!isDistrictSelectMode);
  };

  const loadDistrictOptions = async (
    inputValue: string,
    callback: (options: Array<{ value: string; label: string }>) => void
  ) => {
    setDistrictLoading(true);
    const result = await getDistricts({ search: inputValue });
    const districts = (result.districts as District[]) || [];
    setDistrictLoading(false);
    callback(
      districts.map(o => ({
        value: o.id,
        label: o.name
      }))
    );
  };

  const fetchExistingOrganizations = async ({
    more = false,
    skip,
    take
  }: {
    more?: boolean;
    skip: number;
    take: number;
  }) => {
    if (formik.values.organizationName.length < 3) {
      setReferenceOrganizations([]);
      setSelectedReferenceOrganization(null);
      setPagination({
        skip: 0,
        take: SEARCH_ITEMS_LENGTH
      });
      setHasMore(false);
      return;
    }
    if (more && !hasMore) {
      return;
    }

    setIsReferenceOrganizationLoading(true);
    const results: {
      organizations: any[];
      referenceOrganizations: any[];
    } = await getReferenceOrganizations({
      search: formik.values.organizationName,
      skip,
      take
    });

    const items = [
      ...results.organizations.map(o => ({
        id: o.id,
        name: o.name,
        district: o.district?.name,
        addressL1: [o.billingAddress?.addressLine1].filter(Boolean).join(', '),
        addressL2: [
          o.billingAddress?.city,
          o.billingAddress?.stateProvince,
          o.billingAddress?.zipPostal
        ]
          .filter(Boolean)
          .join(', '),
        phone: o.billingAddress?.phone
      })),
      ...results.referenceOrganizations.map(o => ({
        refId: o.id,
        name: o.schName,
        district: o.district?.leaName,
        addressL1: [o.mstreet1].filter(Boolean).join(', '),
        addressL2: [o.mcity, o.mstate, o.mzip].filter(Boolean).join(', '),
        phone: o.phone
      }))
    ];

    if (!more) {
      if (items.length === 0) {
        setSelectedReferenceOrganization(null);
        setReferenceOrganizations([]);
      } else {
        setReferenceOrganizations(items);
      }
    } else {
      setReferenceOrganizations([...referenceOrganizations, ...items]);
    }

    setHasMore(!(items.length < SEARCH_ITEMS_LENGTH));
    setIsReferenceOrganizationLoading(false);
  };

  const [debouncedValue, setDebouncedValue] = useState(
    formik.values.organizationName
  );
  useEffect(() => {
    if (useReferenceOrganization) {
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(formik.values.organizationName);
    }, SEARCH_DEBOUNCE_TIME);

    return () => {
      clearTimeout(handler);
    };
  }, [formik.values.organizationName]);

  useEffect(() => {
    if (useReferenceOrganization) {
      return;
    }
    if (debouncedValue.length < 3) {
      setReferenceOrganizations([]);
      setSelectedReferenceOrganization(null);
      setPagination({
        skip: 0,
        take: SEARCH_ITEMS_LENGTH
      });
      setHasMore(false);
      return;
    }
    setPagination({
      skip: 0,
      take: SEARCH_ITEMS_LENGTH
    });
    setHasMore(false);
    fetchExistingOrganizations({
      skip: 0,
      take: SEARCH_ITEMS_LENGTH
    });
  }, [debouncedValue]);

  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = 'hidden';

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const loadMore = async () => {
    setPagination({
      ...pagination,
      skip: pagination.skip + SEARCH_ITEMS_LENGTH
    });
    await fetchExistingOrganizations({
      more: true,
      skip: pagination.skip + SEARCH_ITEMS_LENGTH,
      take: SEARCH_ITEMS_LENGTH
    });
  };

  return (
    <>
      <div
        className="h-screen w-0  sm:w-1/4 md:w-1/2 lg:w-2/3 fixed bg-black opacity-30 z-10 top-0 bottom-0 left-0 overflow-hidden"
        onClick={props.onCancel}
      />
      <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
        <FormWrapper
          className="px-8 py-4"
          formik={
            useReferenceOrganization ? referenceOrganizationFormik : formik
          }
          onCancel={props.onCancel}
        >
          <div className="mb-6">
            <div className="flex justify-between mb-4">
              <h3 className="text-primary font-bold text-2xl">
                Create a New Organization
              </h3>
              <button
                type="button"
                className="text-3xl"
                onClick={props.onCancel}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-primary hover:text-primary-dark cursor-pointer"
                />
              </button>
            </div>
            <p className="text-gray-700">
              Use this form to request the addition of a new organization
            </p>
          </div>
          {!useReferenceOrganization ? (
            <>
              <FormField<IForm>
                name="organizationType"
                type="select"
                options={
                  organizationTypesData?.organizationTypes?.map(t => ({
                    label: t.label,
                    value: t.id
                  })) || []
                }
                isLoading={isLoadingOrganizationTypes}
                required
              />
              <FormField<IForm> name="organizationName" type="text" required />
            </>
          ) : null}

          {isReferenceOrganizationLoading && (
            <div className="mt-2 mb-4 p-4 border border-gray-200 rounded-md bg-gray-50">
              <h4 className="text-gray-700 font-medium mb-2 flex items-center">
                <div className="flex gap-1">
                  <div
                    className="w-2 h-2 rounded-full bg-gray-500 animate-bounce"
                    style={{ animationDelay: '0ms' }}
                  ></div>
                  <div
                    className="w-2 h-2 rounded-full bg-gray-500 animate-bounce"
                    style={{ animationDelay: '150ms' }}
                  ></div>
                  <div
                    className="w-2 h-2 rounded-full bg-gray-500 animate-bounce"
                    style={{ animationDelay: '300ms' }}
                  ></div>
                </div>
              </h4>
            </div>
          )}
          <div>
            {useReferenceOrganization ? (
              <Button
                size="sm"
                type="button"
                text="Enter Organization Manually"
                onClick={() => {
                  setUseReferenceOrganization(false);
                  setSelectedReferenceOrganization(null);
                }}
              />
            ) : null}
          </div>
          {!!referenceOrganizations?.length && (
            <h4 className="text-gray-700 font-medium mt-1 -mb-0.5">
              <span>We found an organization(s) matching your entry</span>
            </h4>
          )}
          {referenceOrganizations.map((r, i) => {
            const isCurrentOrgUsed =
              (selectedReferenceOrganization?.id === r.id &&
                r.id &&
                selectedReferenceOrganization?.id) ||
              (selectedReferenceOrganization?.refId === r.refId &&
                r.refId &&
                selectedReferenceOrganization?.refId);
            return (
              <div
                key={i}
                className={classNames({
                  'mt-1 px-4 py-2.5 rounded-md bg-gray-50': true,
                  'border border-gray-300':
                    !useReferenceOrganization || !isCurrentOrgUsed,
                  'border-2 border-primary':
                    useReferenceOrganization && isCurrentOrgUsed
                })}
              >
                {useReferenceOrganization && isCurrentOrgUsed && (
                  <h4 className="text-gray-700 font-medium mb-2">
                    <span>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-2"
                      />
                      We will use this organization
                    </span>
                  </h4>
                )}

                <div className="flex justify-between items-center">
                  <div className="w-3/4">
                    <div className="font-semibold">{r.name}</div>
                    <div className="text-sm text-gray-600">{r.district}</div>
                    <div className="text-sm text-gray-500 mt-1">
                      {r.addressL1}
                      {!!r.addressL2 && (
                        <>
                          <br />
                          {r.addressL2}
                        </>
                      )}
                      {!!r.phone && (
                        <>
                          <br />
                          {r.id ? formatPhoneNumber(r.phone) : r.phone}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-1/4">
                    {!useReferenceOrganization || !isCurrentOrgUsed ? (
                      <Button
                        size="sm"
                        text="Use this organization"
                        type="button"
                        onClick={() => {
                          setUseReferenceOrganization(true);
                          setSelectedReferenceOrganization(r);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            {referenceOrganizations?.length && hasMore ? (
              <Button
                size="sm"
                type="button"
                text="Load more"
                onClick={() => {
                  loadMore();
                }}
              />
            ) : null}
          </div>

          {!useReferenceOrganization ? (
            <>
              {!useReferenceOrganization ? (
                <FormField<IForm>
                  label="This organization is not part of a district"
                  type="checkbox"
                  name="isUnaffiliatedToDistrict"
                />
              ) : null}

              {formik.values.isUnaffiliatedToDistrict ? (
                <></>
              ) : (
                <>
                  {isDistrictSelectMode ? (
                    <FormField<IForm>
                      name="district"
                      type="async-select"
                      required
                      loadOptions={debounce(
                        loadDistrictOptions,
                        SEARCH_DEBOUNCE_TIME
                      )}
                      onChange={data =>
                        formik.setFieldValue('district', data, true)
                      }
                      isLoading={isLoadingDistricts}
                    />
                  ) : (
                    <FormField<IForm>
                      label="New District"
                      name="districtName"
                      required
                      type="text"
                    />
                  )}

                  <div>
                    <FontAwesomeIcon
                      icon={isDistrictSelectMode ? faPlus : faTimes}
                      onClick={handleChangeMode}
                      className="cursor-pointer fa-xs text-secondary"
                    />
                    <span
                      className="ml-2 cursor-pointer text-xs text-primary"
                      onClick={handleChangeMode}
                    >
                      {isDistrictSelectMode
                        ? 'New District'
                        : 'Look for existing district'}
                    </span>
                  </div>
                </>
              )}
            </>
          ) : null}

          {!useReferenceOrganization ? (
            <>
              <FormField<IForm>
                name="addressLine1"
                type="text"
                label="Address 1"
                placeholder="Address 1"
                required
              />
              <FormField<IForm>
                name="addressLine2"
                type="text"
                label="Address 2"
                placeholder="Address 2"
              />
              <FormField<IForm> name="city" type="text" required />

              {formik.values.country !== 'United States' ? (
                <FormField<IForm> name="stateProvince" type="text" required />
              ) : (
                <FormField<IForm>
                  name="stateProvince"
                  label="State"
                  placeholder="State"
                  type="select"
                  options={states.map(s => ({
                    label: s.name,
                    value: s.value
                  }))}
                  required
                />
              )}
              <FormField<IForm>
                name="country"
                type="select"
                options={countries.map(c => ({
                  label: c.name,
                  value: c.name
                }))}
                onChange={v => {
                  if (v.value !== 'United States') {
                    formik.setFieldValue('stateProvince', '');
                  }
                }}
                required
              />
              <FormField<IForm>
                name="zipPostal"
                label="Zip / Postal Code"
                type="text"
                required
              />
              <FormField<IForm>
                name="phone"
                type="mask"
                mask="phoneNumber"
                required
              />
            </>
          ) : null}
          <>
            <FormField<IForm>
              name="position"
              type="select"
              isLoading={props.isLoadingPositions}
              options={props.positionOptions}
              onChange={e => {
                formik.setFieldValue('position', e?.value);
                referenceOrganizationFormik.setFieldValue('position', e?.value);
              }}
              required
            />
            <FormField<IForm>
              name="grades"
              type="select"
              isLoading={props.isLoadingGrades}
              isMulti
              options={props.gradeOptions}
              onChange={e => {
                formik.setFieldValue(
                  'grades',
                  e?.map((el: any) => el?.value)
                );
                referenceOrganizationFormik.setFieldValue(
                  'grades',
                  e?.map((el: any) => el?.value)
                );
              }}
              required
            />
          </>
        </FormWrapper>
      </aside>
    </>
  );
};

export default CreateOrganizationModal;
